import React from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { selectActiveDevice } from "Components/ActiveDevice/activeDeviceSlice";
import Paper from "@mui/material/Paper";
import { getMonthlyPM25Colors } from "Utils/colors";
import NotActive from "./NotActive";
import { isMobile } from "config";

const mobile = isMobile();

const Report = styled.div`
  width: 100%;
  padding: ${mobile ? "0 10px 40px" : "0 10px"};
  box-sizing: border-box;
`;

const Links = styled.div`
  & a {
    color: #571c1b;
    &:hover {
      color: #571c1b;
    }
  }
`;

const YearlyStats = styled(Paper)`
  margin: 10px 0 10px;
  padding: 4px;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #571c1b;
  p {
    margin: 0.25em 0;
    font-size: 0.85em;
    line-height: 1.25;
    text-align: justify;
    padding: 2px 4px;
  }
  table {
    width: 100%;
    font-size: 0.8em;
    color: #555;
    border-spacing: 0;
    border-collapse: collapse;
  }
  thead th {
    color: #fff;
    line-height: 1.25;
    font-weight: 500;
    &:nth-of-type(1) {
      background-color: #e36c09;
    }
    &:nth-of-type(2) {
      background-color: #31859b;
    }
    &:nth-of-type(3) {
      background-color: #376190;
    }
  }
  tbody {
    th {
      background: #fac08f;
      font-weight: 700;
      color: #914200;
      line-height: 2;
    }
    td {
      &:nth-of-type(1) {
        background: #dbeef3;
      }
      &:nth-of-type(2) {
        background: #dbe5f1;
      }
    }
  }
  td {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0 5px;
    text-align: center;
    font-weight: 500;
    background: #e5e0ec;
    color: #571c1b;
  }
  div {
    text-align: center;
    font-size: 0.8em;
    margin-bottom: 2px;
    line-height: 2;
  }
`;

const overLimitStyle = {
  color: "#c00000",
};

const MonthlyAvg = styled(Paper)`
  margin: 10px 0 10px;
  overflow: hidden;
  padding: 4px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  & table {
    width: 100%;
    color: #555;
    border-spacing: 0;
    border-collapse: collapse;
  }
  & th {
    color: #555;
    font-size: 0.8em;
    background-color: #e4e4e4;
    line-height: 2;
    font-weight: 500;
  }
  & tbody th {
    background: #ccc1d9;
    font-weight: 700;
    color: #571c1b;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  & thead th {
    color: #fff;
    border-bottom: 1px solid #fff;
    &:nth-of-type(1) {
      background: #5f497a;
    }
    &:nth-of-type(2) {
      background: #366092;
    }
    &:nth-of-type(3) {
      background: #366092;
    }
    &:nth-of-type(4) {
      background: #5f497a;
    }
  }
  & td {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0 5px;
    text-align: center;
    color: #fff;
    font-weight: 500;
  }
`;

const MonthlyTableWrap = styled.div`
  border: 1px solid #5f497a;
`;

const MonthlyTitle = styled.div`
  text-align: center;
  font-size: 0.8em;
  margin-bottom: 2px;
  line-height: 2;
`;

function ReportPage() {
  const device = useSelector(selectActiveDevice);
  const report = device && device.report ? device.report : null;

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber);

    return date.toLocaleString("pl-pl", { month: "long" });
  };

  const renderMonthlyAvg = () => {
    const { monthsAvg } = report;
    return (
      <MonthlyAvg>
        <MonthlyTitle>Średnie, miesięczne stężenia pyłów</MonthlyTitle>
        <MonthlyTableWrap>
          <table>
            <thead>
              <tr>
                <th>Miesiąc</th>
                <th>PM 2.5</th>
                <th>PM 10</th>
              </tr>
            </thead>
            <tbody>
              {monthsAvg.map((r) => (
                <tr key={r.month}>
                  <th>{getMonthName(r.month)}</th>
                  <td style={getMonthlyPM25Colors(r.pm25)}>{r.pm25}</td>
                  <td style={getMonthlyPM25Colors(r.pm25)}>{r.pm10}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </MonthlyTableWrap>
      </MonthlyAvg>
    );
  };

  const renderYearlyStats = () => {
    const { coverage, pm10avg, pm10over, pm25avg, pm25over } = report;
    const pr25over = parseInt((100 * pm25avg) / 5);
    const pr10over = parseInt((100 * pm10avg) / 45);
    const pr25days = parseInt((100 * pm25over) / 35);
    const pr10days = parseInt((100 * pm10over) / 35);
    const style = (pr) => (pr > 100 ? overLimitStyle : undefined);
    return (
      <YearlyStats>
        <table>
          <thead>
            <tr>
              <th>Pomiar</th>
              <th>
                μm/m<sup>3</sup>
                <br />
                (%)
              </th>
              <th>
                Dni
                <br />
                (%)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>PM 2,5</th>
              <td style={style(pr25over)}>
                {pm25avg} ({pr25over}%)
              </td>
              <td style={style(pr25days)}>
                {pm25over} ({pr25days}%)
              </td>
            </tr>
            <tr>
              <th>PM 10</th>
              <td style={style(pr10over)}>
                {pm10avg} ({pr10over}%)
              </td>
              <td style={style(pr10days)}>
                {pm10over} ({pr10days}%)
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          Średnie roczne stężenie pyłów i liczba dni, w których to stężenie
          zostało przekroczone (wg normy WHO 2021). Dopuszcza się przekroaczanie
          dobowych norm maksymalnie 35 razy w roku. Kompletność danych:{" "}
          {coverage} %
        </p>
      </YearlyStats>
    );
  };

  const renderLinks = () => {
    return (
      <Links>
        <a
          href="/Raport zanieczyszczen powietrza Swarzedz i okolice - 2018.pdf"
          target="_blank"
        >
          Raport zanieczyszczen powietrza Swarzedz i okolice - 2018.pdf
        </a>
      </Links>
    );
  };

  if (!device) return "wczytuję dane...";
  if (!Boolean(report)) return <NotActive device={device} />;
  return (
    <Report>
      {renderYearlyStats()}
      {renderMonthlyAvg()}
      {renderLinks()}
    </Report>
  );
}

export default ReportPage;
