import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { selectActiveDevice } from "Components/ActiveDevice/activeDeviceSlice";
import ChartCaqi from "Components/ChartCaqi";
import AirWidget from "Components/AirWidget";
import NotActive from "./NotActive";
import Paper from "@mui/material/Paper";
import { isMobile } from "config";
import { setPageMeta } from "Utils/functions";

const mobile = isMobile();

const Info = styled.div`
  width: 100%;
  padding: ${mobile ? "0 10px 40px" : "0 10px"};
  box-sizing: border-box;
`;

const Alarm = styled.p`
  font-size: 0.75em;
  color: #a1124d;
  text-align: center;
  font-weight: 600;
  margin: 4px 0 0;
`;

const Results = styled(Paper)`
  margin: 10px 0 10px;
  overflow: hidden;
  padding: 4px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  table {
    width: 100%;
    color: #555;
    border-spacing: 0;
    border-collapse: collapse;
  }
  th {
    color: #555;
    font-size: 0.8em;
    background-color: #e4e4e4;
    line-height: 2;
    font-weight: 500;
  }
  tbody th {
    background: #fac08f;
    font-weight: 700;
    color: #914200;
  }
  thead th {
    color: #fff;
    &:nth-of-type(1) {
      background: #e36c09;
    }
    &:nth-of-type(2) {
      background: #31859b;
    }
    &:nth-of-type(3) {
      background: #366092;
    }
    &:nth-of-type(4) {
      background: #5f497a;
    }
  }
  td {
    border-top: 1px solid #ccc;
    padding: 0 5px;
    text-align: center;
    &:nth-of-type(1) {
      background: #dbeef3;
      color: #205867;
    }
    &:nth-of-type(2) {
      background: #dbe5f1;
      color: #0f243e;
    }
    &:nth-of-type(3) {
      background: #e5e0ec;
      color: #571c1b;
    }
    &:nth-of-type(4) {
      background: #e5e0ec;
      color: #571c1b;
    }
  }
  div {
    text-align: center;
    font-size: 0.8em;
    margin-bottom: 2px;
    line-height: 2;
  }
`;

function getAlarmMsg(pm10h24) {
  if (pm10h24 > 150)
    return "Przekroczony został poziom alarmowy wynoszący 150 µg/m<small><sup>3</sup></small> dobowego zanieczyszczenia powietrza dla PM10.";
  if (pm10h24 > 100)
    return "Przekroczony został poziom informowania wynoszący 100 µg/m<small><sup>3</sup></small> dobowego zanieczyszczenia powietrza dla PM10.";
  return false;
}

function InfoPage() {
  const device = useSelector(selectActiveDevice);
  const active = device && device.caqi !== undefined;

  useEffect(() => {
    if (!active) return;
    setPageMeta(device);
  }, [device]);

  const renderLoading = () => "Wczytuję dane...";

  const renderData = () => {
    const {
      caqi,
      pm25,
      pm10,
      caqi24,
      caqiTemp,
      pm25temp,
      pm25h24,
      pm10temp,
      pm10h24,
    } = device;

    const pm25pr = parseInt(pm25h24 / 0.15);
    const pm10pr = parseInt(pm10h24 / 0.45);
    const alarmMsg = getAlarmMsg(pm10h24);
    return (
      <Info>
        <AirWidget
          device={device}
          style={mobile ? { marginTop: 10 } : undefined}
        />

        <Results elevation={2}>
          <div>Pomiary oraz % normy WHO 2021</div>
          <table cellSpacing="0">
            <thead>
              <tr>
                <th>Pomiar</th>
                <th>Chwilowy</th>
                <th>Godzinowy</th>
                <th colSpan={2}>Dobowy</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>CAQI:</th>
                <td>{caqiTemp ? caqiTemp : "-"}</td>
                <td>{caqi}</td>
                <td>{caqi24}</td>
                <td></td>
              </tr>
              <tr>
                <th>PM2,5:</th>
                <td>{pm25temp ? Math.round(pm25temp) : "-"}</td>
                <td>{pm25}</td>
                <td>{pm25h24}</td>
                <td>
                  <strong>{pm25pr}%</strong>
                </td>
              </tr>
              <tr>
                <th>PM10:</th>
                <td>{pm10temp ? Math.round(pm10temp) : "-"}</td>
                <td>{pm10}</td>
                <td>{pm10h24}</td>
                <td>
                  <strong>{pm10pr}%</strong>
                </td>
              </tr>
            </tbody>
          </table>
          {Boolean(alarmMsg) && (
            <Alarm dangerouslySetInnerHTML={{ __html: alarmMsg }} />
          )}
        </Results>
        <ChartCaqi data={device} />
      </Info>
    );
  };

  if (active) {
    return renderData();
  } else if (!device) {
    return renderLoading();
  }
  return <NotActive device={device} />;
}

export default InfoPage;
