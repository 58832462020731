import React from "react";
import styled from "@emotion/styled";
import FlutterDashIcon from "@mui/icons-material/FlutterDash";
import Paper from "@mui/material/Paper";

const Content = styled.div`
  text-align: center;
  & h3 {
    color: #953734;
    opacity: 0.75;
  }
`;

const Icon = styled(FlutterDashIcon)`
  font-size: 96px;
  color: #e36c09;
  line-height: 1;
`;

const IconWrap = styled(Paper)`
  border-radius: 15px;
  display: inline-flex;
  box-sizing: border-box;
  padding: 20px;
  justify-content: center;
  align-items: center;
  margin: 40px 0 30px;
`;

function NotActive(props) {
  const { device } = props;
  const { lastSeen, id } = device;
  // should return only time from lastSeen
  let time = lastSeen
    ? new Date(lastSeen * 1000).toLocaleTimeString().substring(0, 5)
    : "";
  if (time && time[0] === "0") {
    time = time.substring(1);
  }

  let date = lastSeen ? new Date(lastSeen * 1000).toLocaleDateString() : "";
  if (date && date[0] === "0") {
    date = date.substring(1);
  }

  if (!lastSeen) {
    console.log("Device ID: ", id, " not active for over a year");
  }
  let message;
  if (device.caqi) {
    message = "Przepraszamy, raport niedostępny. Zbyt mała ilość danych.";
  } else {
    message = lastSeen
      ? `Przepraszamy, czujnik nieaktywny. Ostatnie dane z czujnika zarejestrowano ${date} o godz. ${time}.`
      : "Przepraszamy, brak danych.";
  }
  return (
    <Content>
      <IconWrap>
        <Icon />
      </IconWrap>
      <h3>{message}</h3>
    </Content>
  );
}

export default NotActive;
